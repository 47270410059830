<template>
    <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="serviceDeploy-container common-padding">
        <breadcrumb :BreadcrumbData="BreadcrumbData" class="common-noMarginLeft"></breadcrumb>
        <div class="common-screen-container common-noMarginLeft" style="padding-left: 0;">
            <div class="common-input-container">
                <span>店铺名称:</span>
                 <el-input class="common-screen-input" v-model="params.shopName" placeholder="店铺名称" clearable></el-input>
            </div>
            <div class="common-input-container">
                <span>预警状态:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.warningStatus" placeholder="请选择" clearable filterable>
                    <el-option label="咨询爆量" value="1"> </el-option>
                    <el-option label="咨询正常" value="0"> </el-option>
                </el-select>
            </div>
             <!-- <div class="common-input-container">
                <span>所属平台/类目:</span>
                <a-cascader class="common-screen-input" :showSearch="true" v-model="platId" :options="platList" :fieldNames="{ label: 'platformName', value: 'id', children: 'children' }" placeholder="请选择" change-on-select @change="commonFun" />
            </div> -->
            <div class="common-input-container">
                <span>所属平台:</span>
                <el-select class="common-screen-input" v-model="params.platId" placeholder="所属平台" @change="commonFun" clearable filterable >
                <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>所属类目:</span>
                <el-select class="common-screen-input" v-model="params.categoryId" placeholder="所属类目" @change="commonFun" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
                <div class="common-select-page">
                    <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
                    <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
                    <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
                    <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
                </div>
                </el-select>
            </div>
            <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
                <span>所属公司:</span>
                <el-select class="common-screen-input" @change="commonFun" v-model="params.companyId" placeholder="请选择" filterable>
                    <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>所属客户:</span>
                <el-select class="common-screen-input" v-model="params.customerId" placeholder="请搜索客户" @change="commonFun" clearable filterable remote :remote-method="(val)=>{remoteMethod(val, 'custList')}" > 
                    <el-option v-for="item in finishedList" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
            </div>
            <div class="common-input-container">
                <span>运营负责人:</span>
                 <el-input class="common-screen-input" v-model="params.busineseUser" placeholder="管理员" clearable></el-input>
            </div>
            <div>
                <!-- <div class="common-input-container">
                    <common-date @commonGetDate="commonGetDate" :radio="commonRadio" :startDate="commonStartDate"  :endDate="commonEndDate" ref="commonReset"></common-date>
                </div> -->
                <div class="common-input-container">
                <span>日期筛选:</span>
                    <el-date-picker class="common-screen-input" value-format="yyyy-MM-dd" format="yyyy-MM-dd" @change="commonFun" v-model="params.startTime" type="date" placeholder="选择日期"> </el-date-picker>
                </div>
                <div class="common-input-container">
                    <el-button class="common-screen-btn" type="primary" @click="() => {commonFun()}">查 询</el-button>
                    <el-button class="common-screen-btn clear" @click="() => {resetBtn()}" plain>重 置</el-button>
                </div>
            </div>
        </div>
        <el-table class="common-table" :data="tableData">
            <el-table-column width="160px" label="店铺名称" fixed="left">
                <template slot-scope="scope">
                    <el-button @click="() => {detailBtn(scope.row)}" type="text" size="small">{{scope.row.shopName}}</el-button>
                </template>
            </el-table-column>
            <el-table-column width="140px" :formatter="tableColumn" prop="customerName" label="所属客户"></el-table-column>
            <el-table-column width="140px" :formatter="tableColumn" prop="platformName" label="所属平台"></el-table-column>
            <el-table-column width="140px" :formatter="tableColumn" prop="categoryName" label="所属类目"></el-table-column>
            <el-table-column width="120px" label="预警状态">
                <template slot-scope="scope">
                    <div class="common-tableStatus-blue" v-if="scope.row.warningStatus == '0'">咨询正常</div>
                    <div class="common-tableStatus-red" v-if="scope.row.warningStatus == '1'">咨询爆量</div>
                </template>
            </el-table-column>
            <el-table-column width="120px" :formatter="tableColumn" prop="adminNum" label="客服人数"></el-table-column>
            <el-table-column width="120px" :formatter="tableColumn" prop="subaccountNum" label="子账号数量"></el-table-column>
            <el-table-column width="120px" :formatter="tableColumn" prop="consultationNum" label="咨询量"></el-table-column>
            <el-table-column width="140px" :formatter="tableColumn" prop="busineseUser" label="运营负责人"></el-table-column>
            <el-table-column width="140px" :formatter="tableColumn" prop="createName" label="创建人"></el-table-column>
            <el-table-column width="180px" :formatter="tableColumn" prop="createTime" label="创建时间"></el-table-column>
            <el-table-column width="130px" :formatter="tableColumn" prop="note" show-overflow-tooltip label="备注"></el-table-column>
        </el-table>
        <el-pagination
            style="text-align: center"
            @size-change="(e) => pageFun(e, 'pageSize')"
            @current-change="(e) => pageFun(e, 'pageNum')"
            :page-sizes="pagination.pageSizes"
            :page-size="params.pageSize"
            layout="total, prev, pager, next,sizes, jumper"
            :total="pagination.total"
            >
        </el-pagination>
        <service-deploy-detail ref="deployDetail" @loadDetailInfo="loadDetailInfo" :companyId="params.companyId" :drawer="drawer" :handleClose="() => { handleClose() }"></service-deploy-detail>
    </div>
</template>
<script>
import Breadcrumb from "../../components/common/breadcrumb.vue";
// import CommonDate from "../../components/common/commonDate.vue"
import { tableColumn, Config, DateTransform } from "../../utils/index.js"
import ServiceDeployDetail from "./children/serviceDeployDetail.vue"
import { serviceDeployList } from "../../service/customer.js"
import { commonDictSys, getCustList, selectCategory, selectPlatform } from "../../service/common.js"
import { getPlatList } from "../../service/customer.js"
// import { Cascader } from 'ant-design-vue';
export default {
    components: {
        Breadcrumb,
        // CommonDate,
        // aCascader: Cascader,
        ServiceDeployDetail
    },
    data() {
        return {
            input3: '',
            tableColumn,
            rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
            // rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }), // 所属公司下拉
            drawer: false,
            loading: false,
            BreadcrumbData: [
                { title: "首页", isLink: true, url: "/index" },
                { title: "客服", isLink: false },
                { title: "客服调配", isLink: false },
            ],
            tableData: [],
            tableRadio: '今天',
            pagination: {
                total: 0,
                pageSizes: [5, 10, 20, 50, 100]
            },
            params: {
                pageNum: 1,
                pageSize: 10,
                startTime: DateTransform(new Date())
            },
            finishedList: [],
            commonRadio: 1,
            commonStartDate: DateTransform(new Date),
            commonEndDate: DateTransform(new Date),
            platId: [],
            DictSysAll: {'7087cd552e9b4696bca2fcbb6261caae': []},
            platList: [], // 所属平台/类目下拉
            categoryList: [], //类目下拉
            categoryParams: {
                pageNum: 1,
                pageSize: 10,
                name: ''
            },
        }
    },
    created() {
        if (this.rolesCompany && this.rolesCompany.length) {
            this.params.companyId = this.rolesCompany[0].id;
        }
        // this.getPlatList()
        this.selectPlatform();
        this.selectCategory();
        this.commonDictSys('7087cd552e9b4696bca2fcbb6261caae')
        // this.commonGetDate(DateTransform(new Date), DateTransform(new Date))
        this.serviceDeployList()
    },
    methods: {
        selectClear () { // 下拉清空时触发
            this.categoryParams.pageNum = 1
            this.categoryParams.name = ''
            this.selectCategory()
        },
        selectPage(type) { // 下拉分页
            if (type == 'up') {
                this.categoryParams.pageNum--
            } else {
                this.categoryParams.pageNum++
            }
            this.selectCategory()
        },
        remoteCategory(val) { // 所属类目下拉-筛选
            this.categoryParams.pageNum = 1
            this.categoryParams.name = val
            this.selectCategory()
        },
        async selectCategory() { // 类目下拉
            let categoryParams = {...this.categoryParams}
            let { data } = await selectCategory(categoryParams)
            this.categoryList = data.list
        },
        async selectPlatform() { // 平台下拉
            let { data } = await selectPlatform({pageNum: -1})
            this.platList = data
        },
        remoteMethod (val, name) { // 所属客户动态下拉
            if (name == 'custList') {
                this.getCustList(val)
            }
        },
        async getCustList(name) { // 所属客户下拉
            let { data } = await getCustList({customerName: name,companyId: this.params.companyId})
            this.finishedList = data;
        },
        handleClose () { // 关闭弹窗
            this.serviceDeployList()
            this.drawer = false
        },
        async getPlatList() { // 所属平台/类目下拉
            let resData = (await getPlatList({ pageNum: -1 })).data;
            this.platList = resData;
        },
        commonFun() { // 下拉筛选
            this.params.pageNum = 1;
            this.params.pageSize = 10;
            this.serviceDeployList()
        },
        async commonDictSys (uuid) { // 获取字典值接口
            let { data } = await commonDictSys({uuid})
            this.DictSysAll[uuid] = data.list || []
        },
        async serviceDeployList () { // 客服调配列表
            // let platId = [...this.platId];
            let params = {...this.params}
            // if (platId && platId.length) {
            //     params.platformId = platId[0];
            //     if (platId[1]) {
            //         params.categoryId = platId[1];
            //     }
            // }
            this.loading = true
            let { data } = await serviceDeployList(params)
            this.loading = false
            this.pagination.total = data.total;
            this.tableData = data.list;
        },
        detailBtn (row) { // 查看详情按钮
            this.$refs.deployDetail.tableParams(row)
            this.drawer = true
        },
        async loadDetailInfo(id) {
            let { data } = await serviceDeployList({id})
            this.$refs.deployDetail.tableParams(data.list[0])
        },
        resetBtn() {// 重置
            // this.$refs.commonReset.resetFun(this.commonRadio, this.commonStartDate, this.commonEndDate)
            this.params = {
                pageNum: 1,
                pageSize: 10,
                startTime: DateTransform(new Date())
            };
            if (this.rolesCompany && this.rolesCompany.length) {
                this.params.companyId = this.rolesCompany[0].id;
            }
            this.serviceDeployList()
        },
        // commonGetDate (startDate, endDate) { // 时间筛选
        //     this.params.startTime = startDate
        //     this.params.endTime = endDate
        //     this.serviceDeployList()
        // },
        pageFun(e, type) { // 分页
            this.params[type] = e;
            this.serviceDeployList();
        },
    }
}
</script>
<style lang="less" scoped>
.serviceDeploy-container {

}
</style>